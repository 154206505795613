// noinspection JSCheckFunctionSignatures,UnnecessaryLocalVariableJS

import {cloneDeep, defaultConnectData, logger, reducerLog, sss} from "../../../util/Util";
import {CommonState, STATE_TYPE} from "./SpotifyState";
import {convertUriToId, getParsedTrackData} from "./SpotifyConnect";


export const RECONNECT_ACTION = "RECONNECT_ACTION";
export const ACCESS_TOKEN_RECEIVED_ACTION = "ACCESS_TOKEN_RECEIVED_ACTION";
export const PLAYER_STATE_CHANGE_ACTION = "PLAYER_STATE_CHANGE_ACTION";
export const POSITION_CHANGE_ACTION = "POSITION_CHANGE_ACTION";
export const API_STATE_CHANGE_ACTION = "API_STATE_CHANGE_ACTION";
export const JS_LOADED_ACTION = "JS_LOADED_ACTION";
export const CONNECTION_ATTEMPTED_ACTION = "CONNECTION_ATTEMPTED_ACTION";
export const CONNECTION_FAILURE_ACTION = "CONNECTION_FAILURE_ACTION";
export const SPOTIFY_MESSAGE = "SPOTIFY_MESSAGE";
export const CONNECTION_SUCCESS_ACTION = "CONNECTION_SUCCESS_ACTION";
export const SPOTIFY_READY_ACTION = "SPOTIFY_READY_ACTION";
export const ATTEMPTED_REFRESH_ACTION = "ATTEMPTED_REFRESH_ACTION";
export const REFRESH_SUCCESS_ACTION = "REFRESH_SUCCESS_ACTION";
export const RESET_FAILED_PLAYS = "RESET_FAILED_PLAYS";
export const REFRESH_FAILURE_ACTION = "REFRESH_FAILURE_ACTION";
export const PLAYER_INITIALIZED_ACTION = "PLAYER_INITIALIZED_ACTION";

export const ATTEMPTED_PLAY_ACTION = "ATTEMPTED_PLAY_ACTION";
export const ATTEMPTED_PAUSED_ACTION = "ATTEMPTED_PAUSED_ACTION";
export const PAUSE_SUCCESS_ACTION = "PAUSE_SUCCESS_ACTION";
export const PAUSE_FAILED_ACTION = "PAUSE_FAILED_ACTION";
export const SUCCESSFUL_PLAY_ACTION = "SUCCESSFUL_PLAY_ACTION";
export const SET_PLAY_TYPE = "SET_PLAY_TYPE";
export const SET_NOW_PLAYING = "SET_NOW_PLAYING";
export const FAILED_PLAY_ACTION = "FAILED_PLAY_ACTION";
export const DELETE_DEVICE_ID_ACTION = "DELETE_DEVICE_ID_ACTION";
export const SET_URI_ACTION = "SET_URI_ACTION";
export const SUCCESSFUL_TOGGLE_ACTION = "SUCCESSFUL_TOGGLE_ACTION";
export const FAILED_TOGGLE_ACTION = "FAILED_TOGGLE_ACTION";

export class State {
    equals(obj) {
        return this.name === obj.name
    }
    constructor(name, value, displayStr, icon) {
        this.name = name
        this.value = value
        this.displayStr = displayStr
        this.icon = icon
    }
}


export function spotifyConnectReducer(existingState, action) {
    reducerLog("sptfy-connect", action)
    let prevStatus = existingState?.status?.name
    let copy = cloneDeep(existingState);
    copy.player = existingState.player
    switch (action.type) {
        case RESET_FAILED_PLAYS:
            copy.failedPlayCount = 0
            break;
        case SET_URI_ACTION:
            copy.uri = action.data.uri
            break;
        case DELETE_DEVICE_ID_ACTION:
            if (action?.data?.deviceId === copy?.status?.deviceId) {
                delete copy?.status?.deviceId
                // go back down to ACCESS_TOKEN_VALID if we had passed it
                copy.status = copy.status.value >= sss.ACCESS_TOKEN_VALID_STATUS.value
                    ? sss.ACCESS_TOKEN_VALID_STATUS
                    : copy.status;

                if (copy.status.name.includes("FAILED")) {
                    copy.status = sss.ACCESS_TOKEN_VALID_STATUS;
                }
            }
            break;
        case RECONNECT_ACTION:
            copy.reconnectCount += 1
            copy.player?.disconnect()
            let defaultData = defaultConnectData
            // go back down to the ACCESS_TOKEN_VALID if we had passed it
            defaultData.status = copy.status.value >= sss.ACCESS_TOKEN_VALID_STATUS.value
                ? sss.ACCESS_TOKEN_VALID_STATUS
                : copy.status;

            defaultData.jsLoaded = copy.jsLoaded
            defaultData.player = copy.player
            return defaultData
        case JS_LOADED_ACTION:
            copy.jsLoaded = true
            break;
        case ACCESS_TOKEN_RECEIVED_ACTION:
            let rightNow = Date.now()
            let token = action.data.token
            let expireTimestamp = Number(action.data.expires) * 1000
            let isExpired = expireTimestamp < rightNow;

            // only change the state if this token is more recent than our current one
            if (!copy.accessTokenExpires || expireTimestamp > copy.accessTokenExpires) {
                copy.accessTokenExpires = expireTimestamp;
                copy.accessToken = token;

                let beforeState = copy.status
                let newState = isExpired ? sss.ACCESS_TOKEN_INVALID_STATUS : sss.ACCESS_TOKEN_VALID_STATUS
                if (beforeState.value > newState.value) {
                    copy.status = beforeState
                } else {
                    copy.status = newState
                }
            } else {
                copy.badAccessToken = token
            }
            break;
        case PLAYER_INITIALIZED_ACTION:
            // this could happen out of sync with other events
            copy.status = copy.status.value >= sss.PLAYER_INIT_STATUS.value
                ? copy.status
                : sss.PLAYER_INIT_STATUS
            copy.player = action.data.player
            break;
        case SET_NOW_PLAYING:
            let parsed = getParsedTrackData(action.data)
            copy.nowPlaying = new CommonState(action.data, parsed, STATE_TYPE.NOW_PLAYING);
            break;
        case SPOTIFY_MESSAGE:
            if (action.data && action.data > copy.lastMessageSequence) {
                copy.lastMessageSequence = action.data
            }
            break;
        case POSITION_CHANGE_ACTION:
            let position = action.data.position

            if (typeof position === "number" && copy.playerState && copy.playerState.state) {
                copy.isPush = action.data.isPush
                copy.lastUpdateTime = Date.now();
                copy.playerState.state.position = position
            }
            break;
        case PLAYER_STATE_CHANGE_ACTION:
            if (!action.data) {
                // let d = defaultConnectData;
                // defaultConnectData.player = copy.player
                // defaultConnectData.jsLoaded = copy.jsLoaded
                // defaultConnectData.status = copy.status
                // return d
                copy.playerState = new CommonState(null, null, STATE_TYPE.PLAYER)
                return copy
            }
            // copy.playerState = action.data
            let parse = getParsedTrackData(action.data)
            copy.playerState = new CommonState(action.data, parse, STATE_TYPE.PLAYER)
            copy.isNewSong = !!(copy.playerState?.parsed?.songId && existingState.playerState?.parsed?.songId &&
                (copy.playerState?.parsed?.songId !== existingState.playerState?.parsed?.songId));
            copy.isNewContext = !!(copy.playerState?.parsed?.contextUri && existingState.playerState?.parsed?.contextUri &&
                (copy.playerState?.parsed?.contextUri !== existingState.playerState?.parsed?.contextUri));
            let contextId = convertUriToId(copy?.playerState?.parsed?.contextUri)
            let playerAlbumId = copy?.playerState?.parsed?.albumId
            let playerSongId = copy?.playerState?.parsed?.songId
            let apiAlbumId = copy?.nowPlaying?.parsed?.albumId
            copy.contextMismatch = !!(copy.playerState?.parsed?.contextUri && copy.playerState.parsed.albumId &&
                (contextId !== copy.playerState.parsed.albumId) && (contextId !== copy.playerState.parsed.songId));
            if (playerAlbumId && apiAlbumId && playerAlbumId === apiAlbumId) {
                copy.currentIndex = copy.nowPlaying.parsed.albumTracks.findIndex((at) => at.id === playerSongId)
                copy.isLastIndex = copy.nowPlaying.parsed.albumTracks.length === copy.currentIndex + 1
            }
            copy.nextSong = {}
            if (action.data?.track_window?.next_tracks?.length) {
                let next = action.data?.track_window?.next_tracks[0]
                copy.nextSong = {
                    uid: next["uid"],
                    uri: next["uri"]
                }
            }

            copy.isPush = action.data.isPush
            copy.lastUpdateTime = Date.now();
            let {current_track} = action.data?.track_window;
            logger("state update", action.data)
            logger("player", copy.playerState)
            logger("api", copy.nowPlaying)
            logger(`\t\tCurrently Playing [${current_track?.name}]`, current_track?.artists.map(a => a.name).join(", "));
            logger('\t\tstate:', action.data.paused ? "paused" : "playing");
            logger('\t\tposition:', action.data.position/1000);
            logger('\t\tpush:', copy.isPush);
            logger('\t\turi type:', copy.uriType);
            logger('\t\tnew song?', copy.isNewSong);
            logger('\t\tnew context?', copy.isNewContext);
            logger('\t\tcontext mismatch?', copy.contextMismatch);
            logger('\t\tcurrent index:', copy.currentIndex);
            logger('\t\tlast song?:', copy.isLastIndex);
            copy.status = copy.playerState.isPaused() ? sss.SUCCESSFUL_PAUSE_STATUS : sss.SUCCESSFUL_PLAY_STATUS;
            break;
        case CONNECTION_ATTEMPTED_ACTION:
            copy.status = sss.CONNECTING_STATUS
            copy.connectionAttemptCount += 1
            break;
        case CONNECTION_SUCCESS_ACTION:
            // this could happen out of sync with other events
            copy.status = copy.status.value >= sss.CONNECTED_STATUS.value ? copy.status.value : sss.CONNECTED_STATUS
            break;
        case CONNECTION_FAILURE_ACTION:
            copy.failedConnectionCount += 1
            copy.status = sss.FAILED_CONNECT_STATUS
            break;
        case SPOTIFY_READY_ACTION:
            copy.status = sss.READY_TO_PLAY_STATUS
            copy.deviceId = action.data.deviceId
            break;

        // REFRESH

        case ATTEMPTED_REFRESH_ACTION:
            copy.refreshTokenCount += 1;
            copy.status = sss.REFRESHING_STATUS
            break;
        case REFRESH_SUCCESS_ACTION:
            copy.failedRefreshCount = 0
            copy.status = sss.ACCESS_TOKEN_VALID_STATUS
            copy.player?.disconnect()
            copy.player = null;
            break;
        case REFRESH_FAILURE_ACTION:
            copy.failedRefreshCount += 1
            copy.status = sss.FAILED_REFRESH_STATUS
            break;

        // PLAY URI

        case ATTEMPTED_PLAY_ACTION:
            copy.status = sss.ATTEMPTING_PLAY_STATUS
            copy.failedPlayCount = 0
            break;
        case SUCCESSFUL_PLAY_ACTION:
            copy.uriType = action.uriType
            copy.failedPlayCount = 0
            // once we're at successful_play we can mostly deal with spotify state and queue state instead of this reducer
            copy.status = sss.SUCCESSFUL_PLAY_STATUS
            break;
        case FAILED_PLAY_ACTION:
            copy.uriType = null
            copy.failedPlayCount += 1
            copy.status = sss.FAILED_PLAY_STATUS
            break;

        // PAUSE

        case ATTEMPTED_PAUSED_ACTION:
            copy.status = sss.ATTEMPTING_PAUSE_STATUS
            break;
        case PAUSE_SUCCESS_ACTION:
            copy.status = sss.SUCCESSFUL_PAUSE_STATUS
            break;
        case PAUSE_FAILED_ACTION:
            copy.status = sss.FAILED_PAUSE_STATUS
            break;
        default:
            throw new Error("invalid action in SpotifyConnectData", action.type)
    }

    // did the js load correctly?
    if (!window.onSpotifyWebPlaybackSDKReady) {
        copy.status = sss.STAGE_ZERO_STATUS;
    }
    if (prevStatus !== copy?.status?.name) {
        logger(`\t[${prevStatus} -> ${copy?.status?.name}]`)
    }
    return copy
}


