import {SPOTIFY_SONG} from "../../../util/Util";

export const STATE_TYPE = {
    NOW_PLAYING: "NOW_PLAYING",
    PLAYER: "PLAYER",
    API: "API"
}
export class CommonState {
    constructor(state, parsed, stateSource) {
        // this.id = state.timestamp + ":" + stateSource
        this.state = state
        this.parsed = parsed
        this.stateSource = stateSource
    }

    isPaused() {
        if (this.state === null) {
            return true
        }
        return this.stateSource === STATE_TYPE.PLAYER ?
            this.state?.paused :
            !this.state?.is_playing
    }
    getLastUpdated(){
        return this.state?.timestamp
    }
    getDurationMs() {
        return this.stateSource === STATE_TYPE.PLAYER ?
            this.state?.duration :
            this.state?.item.duration_ms;
    }

    getDuration(){
        let d = this.getDurationMs()
        if (d) {
            return d / 1000;
        }
        return d;
    }
    getProgress() {
        let p = this.stateSource === STATE_TYPE.PLAYER ?
            this.state?.position :
            this.state?.progress_ms
        if (p) {
            return p / 1000;
        }
        return p;
    }
    getAlbum() {
        switch (this.stateSource) {
            case STATE_TYPE.NOW_PLAYING:
                return this.parsed?.albumName
            case STATE_TYPE.PLAYER:
                return this.getTrack()?.album?.name
            default:
                return;
        }
    }
    getAlbumId() {
        switch (this.stateSource) {
            case STATE_TYPE.NOW_PLAYING:
                return this.parsed?.albumId
            case STATE_TYPE.PLAYER:
                return this.getTrack()?.album?.id
            default:
                return;
        }
    }
    getTrack() {
        switch (this.stateSource) {
            case STATE_TYPE.NOW_PLAYING:
                if (this?.parsed?.spotifyType !== SPOTIFY_SONG) {
                    let track = this.parsed?.albumTracks?.filter(tr => tr.id === this.parsed?.songId)
                    return track && track.length ?
                        track[0] :
                        null
                } else {
                    return this.state
                }
            case STATE_TYPE.PLAYER:
                return this.state?.track_window.current_track
            default:
                return;
        }
    }
    getTrackId() {
        return this.getTrack()?.id;
    }

    getUri() {
        return this.getTrack()?.uri
    }
    getHref() {
        return this.getTrack()?.href
    }
    getTrackName() {
        return this.getTrack()?.name
    }
}

export const playerStateDefault = {
    paused: null,
    timestamp: null,
    duration: 0,
    shuffle: false,
    position: 0,
    loading: false,
    repeat_mode: 0,
    playback_id: null,
    playback_quality: null,
    playback_features: {
        hifi_status: null,
    },
    playback_speed: 1,

    track_window: {
        current_track: {
            id: null,
            uri: null,
            type: null,
            uid: null,
            linked_from: {
                uri: null,
                id: null
            },
            media_type: null,
            track_type: null,
            name: null,
            duration_ms: 0,
            artists: [],
            album: {
                name: null,
                uri: null,
                images: []
            },
            is_playable: true
        },
        next_tracks: [],
        previous_tracks: []
    },
    restrictions: {
        disallow_seeking_reasons: [],
        disallow_skipping_next_reasons: [],
        disallow_skipping_prev_reasons: [],
        disallow_toggling_repeat_context_reasons: [],
        disallow_toggling_repeat_track_reasons: [],
        disallow_toggling_shuffle_reasons: [],
        disallow_peeking_next_reasons: [],
        disallow_peeking_prev_reasons: [],
        disallow_resuming_reasons: []
    },
    disallows: {
        seeking: false,
        skipping_next: false,
        skipping_prev: true,
        toggling_repeat_context: false,
        toggling_repeat_track: false,
        toggling_shuffle: false,
        peeking_next: false,
        peeking_prev: false,
        resuming: true
    },

}

// removed from playerState:

// context: {
//     uri: null,
//         metadata: {
//         name: null,
//             uri: null,
//             url: null,
//             current_item: {
//             name: null,
//                 uri: null,
//                 url: null,
//                 uid: null,
//                 content_type: null,
//                 artists: [],
//                 images: [],
//                 estimated_duration: 0,
//                 group: {
//                 name: null,
//                     uri: null,
//                     url: null,
//             }
//         },
//         previous_items: [],
//             next_items: [],
//             options: {
//             shuffled: false,
//                 repeat_mode: null,
//         },
//         restrictions: {
//             pause: [],
//                 resume: [],
//                 seek: [],
//                 skip_next: [],
//                 skip_prev: [],
//                 toggle_repeat_context: [],
//                 toggle_repeat_track: [],
//                 toggle_shuffle: [],
//                 peek_next: [],
//                 peek_prev: []
//         }
//     }
// },