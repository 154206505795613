// noinspection UnnecessaryLocalVariableJS,JSDeprecatedSymbols

import {HttpContext} from "../../../util/hooks/useHttp";
import {MyToastContext} from "../../../util/context/MyToastContext";
import {MediaQueueWrapperContext} from "../state/MediaQueueWrapper";
import {VerifiedAccountsContext} from "../../../util/context/VerifiedAccountsContext";
import {
    defaultConnectData,
    ERROR_TOAST,
    getCryptoSecureRandom, getUriType,
    logError,
    logger,
    shortenAccessToken, SPOTIFY_ALBUM,
    SPOTIFY_AUTH_URL, SPOTIFY_PLAYLIST, SPOTIFY_SONG,
    sss
} from "../../../util/Util";
import {
    useCachedApi
} from "../../../util/hooks/useSessionStorage";
import {
    ACCESS_TOKEN_RECEIVED_ACTION,
    API_STATE_CHANGE_ACTION,
    ATTEMPTED_PAUSED_ACTION,
    ATTEMPTED_PLAY_ACTION,
    ATTEMPTED_REFRESH_ACTION,
    CONNECTION_ATTEMPTED_ACTION,
    CONNECTION_FAILURE_ACTION,
    CONNECTION_SUCCESS_ACTION,
    FAILED_PLAY_ACTION,
    JS_LOADED_ACTION,
    PAUSE_FAILED_ACTION,
    PAUSE_SUCCESS_ACTION,
    PLAYER_INITIALIZED_ACTION,
    RECONNECT_ACTION,
    REFRESH_FAILURE_ACTION,
    REFRESH_SUCCESS_ACTION,
    SET_URI_ACTION,
    SPOTIFY_READY_ACTION, spotifyConnectReducer,
    PLAYER_STATE_CHANGE_ACTION,
    SUCCESSFUL_PLAY_ACTION, SET_NOW_PLAYING, RESET_FAILED_PLAYS, POSITION_CHANGE_ACTION, SPOTIFY_MESSAGE
} from "./SpotifyConnectReducer";
import {createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState} from "react";
import {getSiteFromUrl} from "../util/SiteIcons";
import {site_const} from "../util/site_const";
import useDetectMobileScreen, {XS} from "../../../util/hooks/useDetectMobileScreen";

export const SpotifyContext = createContext(null);


export function convertUriToUrl(url) {
    if (url?.includes(":")) {
        let x = url.split(":")
        let mediaType = x[1]
        let spotifyId = x[2]
        return `spotify:${mediaType}:${spotifyId}`;
    }
}
export function convertUrlToUri(url) {
    if (!url) {
        return null;
    }
    let reg = /.*.spotify.com\/(embed\/)?(intl-\w+\/)?(.*)\/([^/?]*)/
    let match = url.match(reg)
    if (match) {
        let mediaType = match[3]
        let spotifyId = match[4];
        return `spotify:${mediaType}:${spotifyId}`;
    }
    return null;
}

export function convertUriToId(uri) {
    if (uri?.includes(":")) {
        return uri.split(":")[2]
    }
    return uri
}

export function getParsedTrackData(data) {
    const parsedTrackData = {
        artists: [],
        thumbnailUrl: null,
        albumName: null,
        albumId: null,
        albumTracks: [],
        songName: null,
        songId: null,
        isPush: false,
        contextUri: null,
        spotifyType: null
    }
    let spotifyType = getUriType(data.uri)
    parsedTrackData.spotifyType = spotifyType;

    let track_data, album;
    if (data["track_window"]) {
        // for player state
        track_data = data["track_window"]["current_track"]
        if (track_data) {
            album = track_data.album
            album.id = convertUriToId(album.uri)
        }
    } else if (data["tracks"]) {
        // for now playing album
        track_data = data["tracks"]["items"][0]
        if (track_data?.added_at) {
            // this is a playlist
            track_data = track_data.track
        }
        album = data;
        parsedTrackData.albumTracks = data["tracks"]["items"]
    } else {
        // for now playing song
        track_data = data
        album = data.album
    }

    if (track_data && album) {
        // artists
        let artistNames = track_data.artists.map((artist) =>  {
            let id = convertUriToId(artist.uri)
            return {name: artist.name, uri: artist.uri, link: "https://open.spotify.com/artist/" + (artist.id || id)}
        });
        parsedTrackData.artists = artistNames

        // song & album
        parsedTrackData.uid = track_data.uid
        parsedTrackData.songName = track_data.name
        parsedTrackData.songId = track_data.id
        parsedTrackData.albumName = album.name
        parsedTrackData.albumId = album.id
        parsedTrackData.uri = spotifyType === SPOTIFY_ALBUM ? album.uri : data.uri
        parsedTrackData.album_type = album.album_type || album.type
        parsedTrackData.total_tracks = album.total_tracks
        parsedTrackData.isPush = data.isPush

        if (data?.context?.uri) {
            parsedTrackData.contextUri = data.context.uri
        }
        // album image
        let image;
        if (data?.breakpoint?.val === XS.val) {
            image = album.images.find((img) => img.size === "SMALL" || img.height === 64)
        } else {
            image = album.images.find((img) => img.size === "MEDIUM" || img.height === 300)
        }
        if (!image) {
            try {
                image = album.images[0]
            } catch (e) {}
        }
        parsedTrackData.thumbnailUrl = image?.url
    }
    return parsedTrackData;
}

export function SpotifyConnect(props) {
    let [playedUri, setPlayedUri] = useState(false)
    let {breakpoint} = useDetectMobileScreen();

    let httpContext = useContext(HttpContext);
    let toastContext = useContext(MyToastContext);
    let queueContext = useContext(MediaQueueWrapperContext);

    let accountsContext = useContext(VerifiedAccountsContext);
    let {spotifyAccess, spotifyRefresh, spotifyExpires, spotifyUsername} = accountsContext.spotifyAuth;
    let [connectState, connectDispatch] = useReducer(spotifyConnectReducer, defaultConnectData, () => defaultConnectData);

    let spotifyErrorCallback = useCallback((resp, url) => {
        logger("Spotify api error: ", url, resp)
    }, [])


    let getSpotifyApiEndpointNoCache = useCallback((endpoint) => {
        if (endpoint === undefined) {
            logger("Endpoint is undefined (get spotify errror)")
            return Promise.reject("No endpoint given ?!?!")
        }
        let url = "https://api.spotify.com/v1" + endpoint
        logger(`Get endpoint: [${url}] with accessToken: [${shortenAccessToken(spotifyAccess)}]`)
        return httpContext.sendRequest(url, {method: "GET", bearer: spotifyAccess})
            .catch((resp) => {
                spotifyErrorCallback(resp, url)
            })
    }, [httpContext, spotifyAccess, spotifyErrorCallback])

    let apiCache = useCachedApi(site_const.spotify, getSpotifyApiEndpointNoCache);

    let getSpotifyApiEndpoint = useCallback((endpoint) => {
        if (!endpoint) {
            return Promise.reject("No endpoint given ?!?!")
        }
        if (!spotifyAccess) {
            return Promise.reject("No access token yet")
        }
        return apiCache(endpoint, spotifyAccess)
    }, [apiCache, spotifyAccess])

    let putSpotifyApiEndpoint = useCallback((endpoint, body) => {
        if (!endpoint) {
            return Promise.reject("No endpoint given ?!?!")
        }
        if (!spotifyAccess) {
            return Promise.reject("No access token yet")
        }
        let url = "https://api.spotify.com/v1" + endpoint
        return httpContext.sendRequest(url, {body: body, method: "PUT", bearer: spotifyAccess})
            .catch((resp) => spotifyErrorCallback(resp, url))
    }, [httpContext, spotifyAccess, spotifyErrorCallback])

    let getAlbum = useCallback((albumId) => {
        return getSpotifyApiEndpoint("/albums/" + convertUriToId(albumId))
    }, [getSpotifyApiEndpoint])

    let getPlaylist = useCallback((albumId) => {
        return getSpotifyApiEndpoint("/playlists/" + convertUriToId(albumId))
    }, [getSpotifyApiEndpoint])

    let getTrack = useCallback((trackId) => {
        return getSpotifyApiEndpoint("/tracks/" + convertUriToId(trackId))
    }, [getSpotifyApiEndpoint])

    let getPlaybackState = useCallback(() => {
        return getSpotifyApiEndpoint("/me/player").then((resp) => {
            if (resp) {
                logger("getPlaybackState: ", resp)
                onApiStateChange(resp)
            } else {
                logger("getPlaybackState null response")
            }

        }).catch((resp) => {
            logger("Error getting playback state:", resp)
        })
    }, [getSpotifyApiEndpoint])

    let goToSpotifyAuthUrl = useCallback(() => {
        // create a random state variable
        let state = getCryptoSecureRandom();

        // send the state to the server
        let options = {method: "PUT"}
        let errResponse = (res) => {
            logger("Error from spotify", res)
            toastContext.addToast("Error signing in to spotify. Please refresh the page and try again.", ERROR_TOAST)
        }
        httpContext.sendRequest(`/spotifyState/${state}`, options)
            .then((resp) => {
                if (resp && resp.success) {
                    sessionStorage.setItem("prev_location", window.location.pathname)

                    // go to the spotify auth url
                    let url = SPOTIFY_AUTH_URL;
                    url += `&state=${state}`
                    window.location.href = url
                } else {
                    errResponse(resp)
                }
            })
            .catch((resp) => {
                errResponse(resp)
            })
    }, [httpContext, toastContext])


    let getDevices = useCallback(() => {
        return getSpotifyApiEndpoint("/me/player/devices")
    }, [getSpotifyApiEndpoint])


    let setDevice = useCallback((device) => {
        if (!device || !device.id) {
            return Promise.reject("No device id given")
        }
        return putSpotifyApiEndpoint("/me/player/", {device_ids: [device.id]})
    }, [putSpotifyApiEndpoint])

    let api = useMemo(() => {
        return {
            getAlbum: getAlbum,
            getPlaylist: getPlaylist,
            setDevice: setDevice,
            getTrack: getTrack,
            getDevices: getDevices,
            goToSpotifyAuthUrl: goToSpotifyAuthUrl,
            getPlaybackState: getPlaybackState,
        }
    }, [getAlbum, getDevices, getPlaybackState, getPlaylist, getTrack, goToSpotifyAuthUrl, setDevice])

    const onPlaySuccess = useCallback((index, uriType) => {
        queueContext.setPlayingDisplay()
        connectDispatch({type: SUCCESSFUL_PLAY_ACTION, index: index, uriType: uriType})
    }, [queueContext]);

    const onPlayFailure = useCallback(() => {
        queueContext.setPausedDisplay()
        connectDispatch({type: FAILED_PLAY_ACTION})
    }, [queueContext]);

    const onPause = useCallback(() => {
        queueContext.setPausedDisplay()
        connectDispatch({type: PAUSE_SUCCESS_ACTION})
    }, [queueContext]);

    const onPauseFailed = useCallback(() => {
        queueContext.setPlayingDisplay()
        connectDispatch({type: PAUSE_FAILED_ACTION})
    }, [queueContext]);

    let refreshSpotifyOauth = useCallback(async () => {
        onRefreshAttempt()
        logger("Refreshing spotify token")
        httpContext.sendRequest("/spotifyRefresh", "PUT")
            .then((resp) => {
                let spotifyObj = {
                    spotifyAccess: resp.spotifyAccess,
                    spotifyRefresh: resp.spotifyRefresh,
                    spotifyExpires: resp.spotifyExpires,
                    spotifyUsername: spotifyUsername,
                    // spotifyDeviceId: resp.deviceIc
                }

                accountsContext.setSpotifyAuth(spotifyObj);
                onRefreshSuccess()
            })
            .catch((e) => {
                logger("Error refreshing: ", e)
                if (accountsContext.loggedIn) {
                    toastContext.addToast("Error connecting with spotify. " +
                        "Click on Profile to re-authenticate", ERROR_TOAST);
                } else {
                    toastContext.addToast("Error connecting with spotify. " +
                        "Create an account or login to this site, then authenticate with spotify", ERROR_TOAST);
                }
                onRefreshFailure()
            })
    }, [accountsContext, accountsContext.loggedIn, httpContext, spotifyUsername, toastContext])


    let playUri = useCallback((contextIndex, uri) => {
        let uriToPlay = uri ? uri : connectState.uri;
        if (!uriToPlay) {
            logger("Null uri cannot play")
            return;
        }
        if (connectState.failedPlayCount > 2) {
            logger("This uri has failed too many times", uriToPlay, "so we are not playing it")
            toastContext.addToast("Failed to play this track", ERROR_TOAST, true)
            return;
        }
        // play a song
        let jsonData = {uris: [uriToPlay]}

        if (uriToPlay?.includes(":album:")) {
            // play an album
            jsonData = {
                context_uri: uriToPlay,
                offset: {
                    position: contextIndex ? contextIndex : 0
                },
                position_ms: 0
            }
        }
        onPlayAttempt()
        logger("playing uri: ", uriToPlay)

        // Tell spotify to start playing on this device (R4R)
        httpContext.sendRequest(`https://api.spotify.com/v1/me/player/play?device_id=${connectState.deviceId}`, {
            readBody: true,
            method: "PUT",
            bearer: spotifyAccess,
            body: JSON.stringify(jsonData)
        }).then((resp) => {
            // logger("PLAYED: ", resp)
            onPlaySuccess(contextIndex || 0, getUriType(uriToPlay))
        }).catch((e) => {
            logError(`Failed to play uri: ${uriToPlay}`);
            logError("Resp:", e.error);
            toastContext.addToast("Error playing this song. Try refreshing the page or try another song", ERROR_TOAST)
            onPlayFailure()
            if (e?.error?.message?.includes("Device not found")) {
                httpContext.sendRequest("/spotifyDevice?deviceId=" + connectState.deviceId, "DELETE")
                accountsContext.setSpotifyAuth({
                    spotifyAccess: spotifyAccess,
                    spotifyRefresh: spotifyRefresh,
                    spotifyExpires: spotifyExpires,
                    spotifyDeviceId: null,
                    spotifyUsername: spotifyUsername
                });
                redoConnection()
            }

            if (e?.error?.message?.includes("access token expired")) {
                refreshSpotifyOauth().then()
            }
        })
    }, [connectState.uri, connectState.failedPlayCount, connectState.deviceId, httpContext, spotifyAccess, toastContext, onPlaySuccess, onPlayFailure, accountsContext, spotifyRefresh, spotifyExpires, spotifyUsername, refreshSpotifyOauth])


    let resumeSpotify = useCallback(() => {
        onPlayAttempt()
        if (connectState.player) {
            logger("Resuming spotify")
            connectState.player.resume().then((resp) => {
                // logger("RESUMED: ", resp)
                // tell SpotifyConnectReducer the request succeeded
                onPlaySuccess(connectState.currentIndex, connectState.uriType)
            }).catch((err) => {
                // tell SpotifyConnectReducer the request failed
                onPlayFailure()
                queueContext.setPausedDisplay()
                logger("In pause. Error: ", err)
            })
        }
    }, [connectState.currentIndex, connectState.player, connectState.uriType, onPlayFailure, onPlaySuccess, queueContext])

    let [volume, setVolume] = useState(1);

    let setSpotifyMute = useCallback((muteStatus) => {
        if (connectState.player) {
            logger("spotify: set mute:", muteStatus ? "mute" : "unmute" )
            connectState.player.setVolume(muteStatus ? 0 : volume).then((resp) => {
                logger("set mute response:", resp)
            })
        }
    }, [connectState.player, volume])

    let pauseSpotify = useCallback(() => {
        if (connectState.player) {
            logger("Pausing spotify")
            onPauseAttempt()
            return connectState.player.pause().then((resp) => {
                logger("\tpause resp: ", resp)
                // this tells SpotifyConnectReducer that the request is finished (also tells mediaqueue)
                onPause()
                return resp;
            }).catch((err) => {
                // this tells SpotifyConnectReducer that the request is finished (also tells mediaqueue)
                onPauseFailed()
                logger("In pause. Error: ", err)
                return err;
            })
        }
        return Promise.resolve(null)
    }, [connectState.player, onPause, onPauseFailed])

    /**
     * Skip to the next song in the album or playlist
     */
    let playNextTrack = useCallback(() => {
        playUri(connectState.currentIndex + 1)
    }, [connectState.currentIndex, playUri])

    /**
     * Skip to the prev song in the album or playlist
     */
    let playPrevTrack = useCallback(() => {
        playUri(connectState.currentIndex - 1)
    }, [connectState.currentIndex, playUri])

    const withTimeout = (millis, promise) => {
        const timeout = new Promise(
            (resolve, reject) => {
                setTimeout(() => reject(`Timed out after ${millis} ms.`), millis)
            });
        return Promise.race([promise, timeout]);
    };

    /**
     * True if the current connectState.status is equal to one of the given statuses
     */
    let isState = useCallback((isThisStatus) => {
        if (!Array.isArray(isThisStatus)) {
            isThisStatus = [isThisStatus]
        }

        for (let index in isThisStatus) {
            let nextStatus = isThisStatus[index];
            if (connectState.status.name === nextStatus.name) {
                return true;
            }
        }
        return false
    }, [connectState?.status?.name])

    let isPlayerMismatch = useCallback(() => {
        return connectState.nowPlaying?.getAlbumId() !== connectState.playerState?.getAlbumId()
    }, [connectState.nowPlaying, connectState.playerState])

    let isNotState = useCallback((stat) => {
        return !isState(stat)
    }, [isState])

    let isInitializing = useCallback(() => {
        return isState([sss.STAGE_ZERO_STATUS, sss.ACCESS_TOKEN_VALID_STATUS, sss.PLAYER_INIT_STATUS,
            sss.CONNECTING_STATUS, sss.CONNECTED_STATUS, sss.FAILED_CONNECT_STATUS, sss.FAILED_REFRESH_STATUS])
    }, [isState])

    let isFailedState = useCallback(() => {
        return isState(
            [sss.FAILED_CONNECT_STATUS, sss.FAILED_REFRESH_STATUS]) //, sss.FAILED_PLAY_STATUS, sss.FAILED_PAUSE_STATUS
    }, [isState])

    let updatePlayerState = useCallback(() => {
        if (connectState.player) {
            logger("updating player state")
            connectState.player.getCurrentState()
                .then((resp) => {
                    if (resp) {
                        resp.breakpoint = breakpoint.val
                        onStateChange(resp, breakpoint.val)
                    } else {
                        logger("getCurrentState failed", resp)
                    }
                })
                .catch((resp) => {
                    logger("getCurrentState error: ", resp)
                })
        }
    }, [breakpoint.val, connectState.player])


    let connectSpotify = useCallback(async () => {
        onConnectAttempt()
        logger("Connecting to spotify player")
        let was_successful;
        try {
            was_successful = await withTimeout(5000, connectState.player.connect())
            if (was_successful) {
                onConnectSuccess()
                return;
            }
        } catch (e) {
            logger("Error connecting: ", e)
        }
        onConnectFail()
    }, [connectState.player])

    const readyListener = useCallback((data) => {
        let deviceId = data.device_id
        logger('Ready with Device ID', deviceId);
        // save it in our db
        httpContext.sendRequest("/spotifyDevice?deviceId=" + deviceId, "PUT")
            .catch((resp) => logger("error from /spotifyDevice PUT: ", resp))
        onSpotifyReady(deviceId)
    }, [httpContext]);


    let initSpotifyPlayer = useCallback(() => {
        logger("Initializing spotify player")
        let newPlayer = new window.Spotify.Player({
            name: "Roundup4Reddit",
            getOAuthToken: callback => callback(spotifyAccess),
            volume: 1
        });

        // Error handling
        newPlayer.addListener('initialization_error', ({message}) => console.error("init err: " + message));
        newPlayer.addListener('authentication_error', ({message}) => {
            logError("spotify auth err: " + message)
            toastContext.addToast("Error connecting to spotify", ERROR_TOAST)
            onConnectFail()
        });
        newPlayer.addListener('account_error', ({message}) => {
            logError("account err: " + message)
        });
        newPlayer.addListener('playback_error', ({message}) => {
            logError("playback err: " + message)
            onPlayFailure()
        });

        // Playback status updates
        newPlayer.addListener('player_state_changed', (d) => {
            if (d) {
                d.isPush = true
                d.breakpoint = breakpoint.val
            }
            onStateChange(d, breakpoint.val)
        });

        // Ready
        newPlayer.addListener('ready', readyListener);
        newPlayer.addListener('autoplay_failed', ({message}) => {
            logError("autoplay_failed: " + message)
            onPlayFailure()
        });

        // Not Ready
        newPlayer.addListener('not_ready', ({device_id}) => {
            logger('Device ID has gone offline', device_id);
            onConnectFail()
        });
        newPlayer.timestamp = Date.now()
        onPlayerInit(newPlayer)
    }, [breakpoint.val, onPlayFailure, readyListener, spotifyAccess, toastContext])

    let queuedUrl = queueContext.getEmbedUrlForCurrentItem()
    let queuedSpotifyUri = useMemo(() => convertUrlToUri(queuedUrl), [queuedUrl])

    let spotifyIsCurrentQueueItem = useMemo(() => {
        let currentItem = queueContext.getCurrentItem();
        return currentItem && currentItem.src && getSiteFromUrl(currentItem.src) === site_const.spotify
    }, [queueContext])

    let [playingNext, setPlayingNext] = useState(false);
    let [muted, setMuted] = useState(false);
    /**
     * Look for song changes, and go to next item in queue if necessary
     */
    useEffect(() => {
        // TODO instead of trying to detect when spotify is autoplaying..
        //  ..we should preemptively add the next item to their queue https://developer.spotify.com/documentation/web-api/reference/add-to-queue
        //  ..or set a timer for when the track will end
        if (spotifyIsCurrentQueueItem) {
            var isMismatch = connectState.contextMismatch && connectState.uriType !== SPOTIFY_PLAYLIST
            if (playingNext && !isMismatch) {
                logger("\tthe correct song is now playing")
                setPlayingNext(false)
            }
            else if (!playingNext && (connectState.isNewSong || connectState.isPush) && isMismatch) {
                if (connectState?.playerState?.parsed?.uid && connectState.playerState.parsed.uid.match(/^q\d{1,2}/)) {
                    // The user added a song to their queue. Do nothing, let it play
                    logger("Doing nothing. uid: ", connectState.playerState.parsed.uid)
                } else {
                    // the current context ended and spotify auto-played a song. Take over
                    logger("\talbum ended. next queue item", connectState?.playerState?.parsed?.uid, connectState?.playerState?.state?.track_window?.current_track)
                    setPlayingNext(true)
                    queueContext.nextQueueItem()
                }
            }
            else if (!playingNext && connectState.isPush && connectState.playerState.isPaused() && connectState.uriType === SPOTIFY_SONG) {
                // single song just finished
                if (queueContext.data.shouldBePlaying) {
                    setPlayingNext(true)
                    logger("\tsingle track ended. next queue item")
                    queueContext.nextQueueItem()
                }
            }
        } else {
            setPlayingNext(false)
        }
    }, [connectState.contextMismatch, connectState.isNewSong, connectState.isPush, connectState.playerState, connectState.uriType, pauseSpotify, playingNext, queueContext, spotifyIsCurrentQueueItem])

    /**
     * Mute or unmute spotify
     */
    useEffect(() => {
        if (spotifyIsCurrentQueueItem) {
            if (queueContext.data.isMuted && !muted) {
                setMuted(true)
                setSpotifyMute(true)
            } else if (!queueContext.data.isMuted && muted) {
                setMuted(false)
                setSpotifyMute(false)
            }
        }
    }, [muted, queueContext.data.isMuted, setSpotifyMute, spotifyIsCurrentQueueItem])
    /**
     * Look at the current status and determine what needs to be done
     */
    useEffect(() => {
        if (!spotifyIsCurrentQueueItem) {
            // the current queue item is not spotify
            if (isState(sss.SUCCESSFUL_PLAY_STATUS) && connectState.playerState.state && !connectState.playerState.isPaused()) {
                // pause spotify if it's still playing
                pauseSpotify()
            } else if (isState([sss.SUCCESSFUL_PLAY_STATUS, sss.SUCCESSFUL_PAUSE_STATUS])){
                // set status to READY_TO_PLAY. So if a subsequent queue item is spotify it will play automatically
                onSpotifyReady(connectState.deviceId)
            }
            if (connectState.playerState?.state) {
                onStateChange(null)
            }
        } else {
            // current queue item is spotify
            logger("SpotifyConnect useEffect. Current status: ", connectState.status.name)
            let queueIsPlaying = queueContext.data.isPlaying || isState(sss.SUCCESSFUL_PLAY_STATUS)
            let queueShouldBePlaying = queueContext.data.shouldBePlaying

            // Decide if we need to take an action based on the current state:
            //      Init spotify player, play a song, update media queue state
            switch (connectState.status.name)  {
                case sss.ACCESS_TOKEN_INVALID_STATUS.name:
                    logger("\taction: refreshSpotifyOauth")
                    refreshSpotifyOauth().then(() => {})
                    break;

                case sss.ACCESS_TOKEN_VALID_STATUS.name:
                    if (connectState.jsLoaded) {
                        if (spotifyAccess && !connectState.deviceId) {
                            // init player object, and request a device id
                            logger("\taction: initSpotifyPlayer")
                            initSpotifyPlayer()
                        } else {
                            // if we already have a device id use it
                            logger("\taction: playUri on existing device !Alert!Disabled")
                            playUri()
                        }
                    } else {
                        logger("\tjs not yet loaded, doing nothing")
                    }
                    break;

                case sss.PLAYER_INIT_STATUS.name:
                    // connect
                    logger("\taction: connectSpotify")
                    connectSpotify().then()
                    break;

                case sss.SUCCESSFUL_PAUSE_STATUS.name:
                    if (queueIsPlaying) {
                        logger("\taction: setPauseV1")
                        queueContext.setPausedDisplay()
                    }
                    break;
                case sss.READY_TO_PLAY_STATUS.name:
                    if (queueIsPlaying && !queueShouldBePlaying) {
                        logger("\taction: setPauseV2")
                        queueContext.setPausedDisplay()
                    }
                    break;
                case sss.SUCCESSFUL_PLAY_STATUS.name:
                    setPlayedUri(true)
                    if (!queueIsPlaying) {
                        logger("\taction: setPlay")
                        queueContext.setPlayingDisplay()
                    }
                    break;
                case sss.FAILED_PAUSE_STATUS.name:
                    logger("\tPAUSE FAILED. todo do something here?")
                // INTENTIONAL FALLTHROUGH
                case sss.FAILED_PLAY_STATUS.name:
                    if (queueIsPlaying) {
                        queueContext.setPausedDisplay()
                        logger("\taction: setPauseV3")
                    }
                    break;
                case sss.FAILED_CONNECT_STATUS.name:
                    queueContext.setPausedDisplay()
                    if (connectState.refreshTokenCount === 0) {
                        // todo force refresh the page?
                        // refresh the token
                        refreshSpotifyOauth().then()
                        logger("\taction: refreshSpotifyOauth")
                    } else if (queueShouldBePlaying && connectState.connectionAttemptCount <= 1) {
                        // redo connection to spotify player
                        logger("\taction: redo connection")
                        redoConnection()
                    }
                    break;
                default:
                    logger("\tnothing to do")
                    break;
            }

            // start playing or resume the current uri
            let isNewUri = queuedSpotifyUri !== connectState.uri
            let shouldStart = !queueIsPlaying && queueShouldBePlaying
            if (queuedSpotifyUri && connectState.deviceId && (isNewUri || shouldStart)) {
                logger("Should start playing")
                if (isState([sss.READY_TO_PLAY_STATUS, sss.SUCCESSFUL_PAUSE_STATUS, sss.FAILED_PLAY_STATUS, sss.SUCCESSFUL_PLAY_STATUS])) {
                    if (isNewUri) {
                        logger("\taction: switch track")
                        setUri(queuedSpotifyUri)

                        if (shouldStart || queueShouldBePlaying) {
                            playUri(null, queuedSpotifyUri)
                            setPlayedUri(true)
                        }
                    } else if (!playedUri) {
                        logger("\taction: playUri ")
                        // start playing a track
                        setPlayedUri(true)
                        playUri()
                    } else if (connectState.failedPlayCount > 0) {
                        logger("\tthis uri failed to play. Giving up ")
                    } else if (connectState.playerState.isPaused()) {
                        // if they are in the middle of listening to a track: resume
                        logger("\taction: spotifyResume")
                        resumeSpotify()
                    }
                } else {
                    logger("\tBut we won't (incorrect status)", connectState?.status?.name)
                }
            }

            // pause
            if (!queueShouldBePlaying && (queueIsPlaying || isState([sss.SUCCESSFUL_PLAY_STATUS]))) {
                if (connectState.isPush) {
                    // spotify was started remotely
                    logger("\tspotify started remotely?")
                    onPlaySuccess()
                } else {
                    logger("\tshouldn't be playing. pausing spotify")
                    pauseSpotify()
                }
            }
        }

        // check for a new access token
        if (spotifyAccess && spotifyAccess !== connectState.accessToken && spotifyAccess !== connectState.badAccessToken) {
            logger("\tnew access token") // , spotifyAccess, spotifyExpires
            onAccessToken(spotifyAccess, spotifyExpires)
        }

        if (accountsContext.spotifyJsLoaded && !connectState.jsLoaded) {
            // this is outside the switch to avoid race condition with getting the access_token
            logger("\tSpotify JS loaded")
            onSpotifyJsLoad()
        }

        return () => {
            connectState?.player?.disconnect()
        }

    }, [accountsContext.spotifyJsLoaded, connectSpotify, connectState, connectState.accessToken, connectState.connectionAttemptCount, connectState.deviceId, connectState.jsLoaded, connectState?.player, connectState.refreshTokenCount, connectState.status, connectState.failedPlayCount, connectState.uri, initSpotifyPlayer, isNotState, isState, playUri, playedUri, queueContext, refreshSpotifyOauth, spotifyAccess, spotifyExpires, pauseSpotify, resumeSpotify, isPlayerMismatch, queuedSpotifyUri, spotifyIsCurrentQueueItem, onPlaySuccess])

    let initTrackData = useCallback((data) => {
        if (!data || data.error === true) {
            return;
        }
        setNowPlayingData(data, breakpoint.val)
    }, [breakpoint.val])


    let getTrackData = useCallback(() => {
        if (!queuedSpotifyUri){
            return;
        }
        var uriType = getUriType(queuedSpotifyUri)
        if (uriType === SPOTIFY_ALBUM) {
            api.getAlbum(queuedSpotifyUri)
                .then(initTrackData)
                .catch((resp) => {
                    logger("album error: ", resp)
                })
        } else if (uriType === SPOTIFY_PLAYLIST) {
            api.getPlaylist(queuedSpotifyUri)
                .then(initTrackData)
                .catch((resp) => {
                    logger("playlist error: ", resp)
                })
        } else if (uriType === SPOTIFY_SONG) {
            api.getTrack(queuedSpotifyUri)
                .then(initTrackData)
                .catch((resp) => {
                    logger("track error: ", resp)
                })
        }
    }, [api, queuedSpotifyUri, initTrackData])


    /**
     * This is where nowPlaying data gets initialized
     */
    let[fetchedWith, setFetchedWith] = useState(null);
    useEffect(() => {
        let newUri = false
        let uri = connectState?.nowPlaying?.parsed?.uri;
        if (queuedSpotifyUri && (uri !== queuedSpotifyUri)) {
            newUri = true;
            logger("NEW URI for nowplaying:", uri, " -> " ,queuedSpotifyUri)
        }
        if (newUri) {
            if (isState([sss.READY_TO_PLAY_STATUS, sss.SUCCESSFUL_PLAY_STATUS, sss.FAILED_PLAY_STATUS,
                sss.SUCCESSFUL_PAUSE_STATUS, sss.FAILED_PAUSE_STATUS])) {
                getTrackData()
                setFetchedWith(spotifyAccess)
            }
        }
    }, [connectState?.nowPlaying, fetchedWith, getTrackData, isState, queuedSpotifyUri, spotifyAccess])


    function setUri(uri) {
        connectDispatch({type: SET_URI_ACTION, data: {uri: uri}})
    }

    function redoConnection() {
        connectDispatch({type: RECONNECT_ACTION})
    }

    function setNowPlayingData(data, breakpoint) {
        data.breakpoint = breakpoint
        connectDispatch({type: SET_NOW_PLAYING, data: data})
    }

    function onPlayAttempt() {
        connectDispatch({type: ATTEMPTED_PLAY_ACTION})
    }

    function onPauseAttempt() {
        connectDispatch({type: ATTEMPTED_PAUSED_ACTION})
    }

    function onStateChange(newState) {
        connectDispatch({type: PLAYER_STATE_CHANGE_ACTION, data: newState})
    }

    function onPositionChange(pos) {
        connectDispatch({type: POSITION_CHANGE_ACTION, data: pos})
    }

    function onApiStateChange(newState) {
        connectDispatch({type: API_STATE_CHANGE_ACTION, data: newState})
    }

    function onSpotifyJsLoad() {
        connectDispatch({type: JS_LOADED_ACTION})
    }

    function onAccessToken(token, expireTimestamp) {
        connectDispatch({type: ACCESS_TOKEN_RECEIVED_ACTION, data: {token: token, expires: expireTimestamp}})
    }

    function onPlayerInit(player) {
        connectDispatch({type: PLAYER_INITIALIZED_ACTION, data: {player: player}})
    }

    function onSpotifyReady(deviceId) {
        connectDispatch({type: SPOTIFY_READY_ACTION, data: {deviceId: deviceId}})
    }


    function resetFailedPlayCount() {
        connectDispatch({type: RESET_FAILED_PLAYS})
    }

    function onRefreshSuccess(token) {
        connectDispatch({type: REFRESH_SUCCESS_ACTION, data: token})
    }

    function onRefreshFailure(token) {
        connectDispatch({type: REFRESH_FAILURE_ACTION, data: token})
    }

    function onRefreshAttempt(token) {
        connectDispatch({type: ATTEMPTED_REFRESH_ACTION, data: token})
    }

    function onConnectAttempt() {
        connectDispatch({type: CONNECTION_ATTEMPTED_ACTION})
    }

    function onConnectSuccess() {
        connectDispatch({type: CONNECTION_SUCCESS_ACTION})
    }

    function onConnectFail() {
        connectDispatch({type: CONNECTION_FAILURE_ACTION})
    }
    function onSpotifyMessage(sequence) {
        connectDispatch({type: SPOTIFY_MESSAGE, data: sequence})
    }

    let seek = useCallback((ms) => {
        connectState.player.seek(ms).then((resp) => {
            logger("seek resp: ", resp)
        })
    }, [connectState.player])


    // TODO I disabled this because spotify sends like 20 alerts for each event.
    //  And connectState.lastMessageSequence wasn't working correctly
    // let messageListener = useCallback((evnt) => {
    //     if (evnt.origin.includes("sdk.scdn")) {
    //         let thisSeq = evnt.data.seq
    //         if (typeof thisSeq === "number" && thisSeq > connectState.lastMessageSequence) {
    //             onSpotifyMessage(thisSeq)
    //             logger("Spotify message", evnt.data.body.topic, evnt.data)
    //             if (evnt?.data?.body?.data?.name === "PROGRESS") {
    //                 onPositionChange(evnt.data.body.data.eventData.position)
    //             } else if(evnt?.data?.body?.data?.name === "PLAYER_STATE_CHANGED") {
    //                 evnt.data.body.data.eventData.isPush = true
    //                 onStateChange(evnt.data.body.data.eventData)
    //             }
    //         }
    //     }
    // }, [connectState.lastMessageSequence])

    // todo make a reusable hook that does this
    // let [addedListener, setAddedListener] = useState(false)
    // useEffect(() => {
    //     if (!addedListener) {
    //         setAddedListener(true)
    //         window.addEventListener("message", messageListener);
    //     }
    // }, [addedListener])


    let exposed = {
        onJsLoad: onSpotifyJsLoad,
        onAccessToken: onAccessToken,
        onConnectAttempt: onConnectAttempt,
        onConnectSuccess: onConnectSuccess,
        onConnectFail: onConnectFail,
        onRefreshSuccess: onRefreshSuccess,
        resetFailedPlayCount: resetFailedPlayCount,
        refreshSpotify: refreshSpotifyOauth,
        playUri: playUri,
        playNextTrack: playNextTrack,
        playPrevTrack: playPrevTrack,
        // shakeItUp: shakeItUp,
        onPause: onPause,
        seek: seek,
        updatePlayerState: updatePlayerState,
        isInitializing: isInitializing,
        isFailedState: isFailedState,
        isState: isState,
        isNotState: isNotState,
        setUri: setUri,
        isPlayerMismatch: isPlayerMismatch,
    }

    let connect = {api, ...exposed}

    return (
        <SpotifyContext.Provider value={{
            spotifyConnect: connect,
            spotifyState: {...connectState,
                spotifyIsCurrentQueueItem: spotifyIsCurrentQueueItem,
                queuedSpotifyUri: queuedSpotifyUri,
            }

        }
        }>
            {props.children}
        </SpotifyContext.Provider>
    )
}