import React, {createContext, useCallback, useContext, useMemo, useState} from "react";
import Button from "react-bootstrap/Button";
import {FaCompressArrowsAlt, FaExpandArrowsAlt} from "react-icons/fa";
import CollapsableCard from "../CollapsableCard";
import {List} from "semantic-ui-react";
import RedditPost from "./RedditPost";
import RedditComment from "./RedditComment";
import {useStateWithSessionStorage} from "../../util/hooks/useSessionStorage";
import {RiCollapseDiagonal2Line, RiExpandDiagonalFill} from "react-icons/ri";
import {logger, queueIcon, SUCCESS_TOAST} from "../../util/Util";
import {MediaQueueWrapperContext} from "../media_queue/state/MediaQueueWrapper";
import {isApprovedEmbeddableSite, isEmbeddable, showExpandoButtonPost} from "../../util/context/SubredditConfigContext";
import parse from "html-react-parser";
import {MyToastContext} from "../../util/context/MyToastContext";
import {Col} from "react-bootstrap";


export const RoundupMatcherContext = createContext({collapseAll: false, expandAll: false});

export default function RoundupMatcher(props) {
    let thisRoundup = props.thisRoundup
    let matcher = props.matcher
    let queueContext = useContext(MediaQueueWrapperContext);
    let toastContext = useContext(MyToastContext);

    let [expandAll, setExpandAll] =  useStateWithSessionStorage(
        matcher.id + "_" + thisRoundup.id + "_expand",
        null)
    let [collapseAll, setCollapseAll] =  useStateWithSessionStorage(
        matcher.id + "_" + thisRoundup.id + "_collapse",
        null)

    let matchedPosts = thisRoundup?.posts[matcher.id]

    let showQueueAll = useMemo(() => {
        let found = matchedPosts.find((p) => {
            return isEmbeddable(p) || p.mirrors.length
        })
        return found != null
    }, [matchedPosts])

    let showExpandAllBtn = useMemo(() => {
        let found = matchedPosts.filter((p) => {
            return showExpandoButtonPost(p)
        })
        if (showQueueAll) {
            return found && found.length >= 3
        }
        return found != null && !!found.length
    }, [matchedPosts])


    let header =(
        <>
            <Col xs={showExpandAllBtn ? 10 : showQueueAll ? 8 : 6} md={showExpandAllBtn ? 5 : showQueueAll ? 5 : 10}>
                <h3 className={"matcher_title"}>
                    {matcher.displayName}
                </h3>
            </Col>
            {showQueueAll &&
                <Button variant={"secondary"} onClick={() => {
                    let count = 0
                    matchedPosts.forEach((p) => {
                        if (isEmbeddable(p)) {
                            let added = addEmbeddableToQueue(p, true)
                            if (added) {
                                count ++;
                            }
                        } else if (p.mirrors.length) {
                            addMirrorToQueue(p.mirrors[0], p, true)
                            count ++;
                        } else {
                            logger("Not queueable", p)
                        }
                    })
                    toastContext.addToast("Added " + count + " items to the queue", SUCCESS_TOAST)
                }}>
                    {queueIcon} Queue All
                </Button>
            }
            {showExpandAllBtn && (
                <>
                    <Button variant={"secondary"} onClick={() => {setExpandAll(Date.now())}}>
                        <RiExpandDiagonalFill/> Expand All
                    </Button>
                    <Button variant={"secondary"} onClick={() => {setCollapseAll(Date.now())}}>
                        <RiCollapseDiagonal2Line /> Collapse All
                    </Button>
                </>
            )}
        </>
    )

    let addEmbeddableToQueue = useCallback((post, suppressToast=false) => {
        let data = {post: post}
        if (post.custom_embed) {
            data["src"] = post.custom_embed
            queueContext.addToQueue(data, suppressToast)
            return true

        } else if (isApprovedEmbeddableSite(post)) {
            Object.assign(data, {src: post.url, title: post.title})
            queueContext.addToQueue(data, suppressToast)
            return true

        } else if (post && post.media_embed?.oembed?.html) {
            try {
                let p = parse(post.media_embed.oembed.html)
                Object.assign(data, p.props)
            } catch (e) {}
            queueContext.addToQueue(data, suppressToast)
            return true

        } else if (post && post.media_embed?.reddit_video) {
            let vid = post.media_embed.reddit_video;
            let props = {
                src: vid.fallback_url,
                width: vid.width,
                height: vid.height,
                title: post.title,
                allow: "autoplay; fullscreen"
            }
            Object.assign(data, props)
            queueContext.addToQueue(data, suppressToast)
            return true
        }
        return false
    }, [queueContext])

    let addMirrorToQueue = useCallback((mir, post, suppressToast=false) => {
        let data = {post: post, src: mir.otherLink}
        queueContext.addToQueue(data, suppressToast)
    }, [queueContext])

    return (
        <RoundupMatcherContext.Provider value={{expandAll: expandAll, setExpandAll: setExpandAll,
            collapseAll: collapseAll, setCollapseAll: setCollapseAll,
            addToQueue: addMirrorToQueue, addEmbeddableToQueue: addEmbeddableToQueue}}>
            <div key={matcher.id} className={"nested_card"}>
                <CollapsableCard
                    cardId={"roundup_matcher_" + matcher.id} className={"roundup_matcher"}
                    body={
                        <List>
                            {matchedPosts && matchedPosts.map((post) => {
                                let element;
                                if (!post.is_comment && post.title) {
                                    element = <RedditPost post={post}/>
                                } else {
                                    element = <RedditComment comment={post}/>
                                }
                                return (
                                    <List.Item key={post.id}>
                                        {element}
                                    </List.Item>
                                )
                            })}
                        </List>
                    }
                    header={header}>

                </CollapsableCard>
            </div>
        </RoundupMatcherContext.Provider>
    )
}
