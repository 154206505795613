export const site_const = {
    google_search: "google_search",
    youtube: "youtube",
    youtu_be: "youtube",
    soundcloud: "soundcloud",
    music_youtube: "music.youtube",
    applemusic: "applemusic",
    music_apple: "applemusic",
    itunes: "itunes",
    spotify: "spotify",
    open_spotify: "spotify",
    deezer: "deezer",
    tidal: "tidal",
    nbcsports: "nbcsports",
    espn: "espn",
    vevo: "vevo",
    music_amazon: "music.amazon",
    pandora: "pandora",
    playmusic: "playmusic",
    amazon: "amazon",
    vimeo: "vimeo",
    fader: "fader",
    dailymotion: "dailymotion",
    bandcamp: "bandcamp",
    facebook: "facebook",
    tmz: "tmz",
    instagram: "instagram",
    twitter: "twitter",
    twitch: "twitch",
    clips_twitch: "twitch",
    megaphone: "megaphone",
    mixcloud: "mixcloud",
    libsyn: "libsyn",
    acast: "acast",
    stitcher: "stitcher",
    radiopublic: "radiopublic",
    tunein: "tunein",
    omny: "omny",
    adultswim: "adultswim",
    npr: "npr",
    outlook: "outlook",
    reddit: "reddit",
    v_redd_it: "reddit",
    lnk_to: "lnk.to",
    fanlink: "fanlink",
    linktree: "linktree",
    feature_fm: "feature_fm",
    distrokid: "distrokid",
    smarturl: "smarturl",
    ingrooves: "ingrooves",
    music_brainz: "musicbrainz",
    genius: "genius",
    imvdb: "imvdb",
    wikipedia: "wikipedia",
    wikidata: "wikidata",
    lastfm: "lastfm",
    official_website: "site",
    blog: "blog",
    bbc: "bbc",
    secondhandsongs: "second-hand-songs",
    boomkat: "boomkat",
    bands_in_town: "bands-in-town",
    equipboard: "equipboard",
    iheartradio: "iheart-radio",
    discogs: "discogs",
    whosampled: "whosampled",
    allmusic: "allmusic",
    rateyourmusic: "rateyourmusic",
    billboard: "billboard",
    spiritofrock: "spirit-of-rock",
    spiritofmetal: "spirit-of-metal",
    audiodb: "audiodb",
    songkick: "songkick",
    songfacts: "songfacts",
    metalarchives: "metal-archives",
    beatport: "beatport",
    tumblr: "tumblr",
    datpiff: "datpiff",
    audiomack: "audiomack",
    streamable: "streamable",
    unknown: "unknown"
}