import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import 'semantic-ui-css/semantic.min.css'
import {Dropdown as SemanticDropdown} from 'semantic-ui-react'
import {useNavigate} from "@reach/router";

import {
    cloneDeep, defaultSubredditConfig,
    ERROR_TOAST,
    getCryptoSecureRandom,
    getDefaultSubredditConfigObject,
    REDDIT_AUTH_URL,
    SUCCESS_TOAST,
    WARNING_TOAST
} from "../../util/Util";
import {VerifiedAccountsContext} from "../../util/context/VerifiedAccountsContext";
import Button from "react-bootstrap/Button";
import {MyToastContext} from "../../util/context/MyToastContext";
import {HttpContext} from "../../util/hooks/useHttp";
import RedditButton from "../RedditButton";
import Loading from "../Loading";
import {SubredditConfigContext} from "../../util/context/SubredditConfigContext";
import {convertBackendToFrontendJson} from "../../util/ConvertJson";
import CollapsableCard from "../CollapsableCard";

const premades = require('./premades.json');

export default function AddSubreddit(props) {
    let nav = useNavigate();
    let httpContext = useContext(HttpContext);
    let configContext = useContext(SubredditConfigContext);
    let verifiedAccountContext = useContext(VerifiedAccountsContext);
    let toastContext = useContext(MyToastContext);

    let redditAccount = verifiedAccountContext.reddit;
    let redditUsername = redditAccount ? redditAccount.username : null;

    let [subredditList, setSubredditList] = useState([]);
    let [subscribedSubredditList, setSubscribedSubredditList] = useState([]);

    let [isLoading, setIsLoading] = useState(false);
    let [isCreateConfigLoading, setIsCreateConfigLoading] = useState(false);
    let subConfigs = props.subConfigs;
    let subConfigNames = subConfigs.map((sc) => sc.subreddit);

    let [abortController, setAbortController] = useState(new AbortController());
    let [selectedSubs, setSelectedSubs] = useState([]);
    let [searchQuery, setSearchQuery] = useState("");
    let dropdownRef = useRef(null);

    const convertSubredditListForDropdown = useCallback((subredditList, sort) => {
        let list = subredditList.map((sub) => {
            let desc = ""
            try {
                desc = `${Number(sub.numSubscribers).toLocaleString()} subscribers`;
            } catch (exception) {
                // ignore
            }
            return {
                key: sub.name,
                value: sub.name,
                text: `${sub.name}`,
                description: desc,
                subscribers: Number(sub.numSubscribers)
            }
        });
        if (sort) {
            list.sort((a, b) => a.key.toLowerCase() > b.key.toLowerCase() ? 1 : -1)
        }
        // TODO this filter won't do anything if /userConfig loads after /userData
        // FIXME fix this
        if (subConfigNames && subConfigNames.length > 0) {
            list = list.filter((next) => !subConfigNames.includes(next.subreddit))
        }
        return list;
    }, [subConfigNames])

    useEffect(() => {
        if (verifiedAccountContext.subredditList.length !== subscribedSubredditList.length) {
            let list = convertSubredditListForDropdown(verifiedAccountContext.subredditList, true);
            setSubredditList(list);
            setSubscribedSubredditList(list);
        }
    }, [redditAccount, setSubredditList, setSubscribedSubredditList, subscribedSubredditList,
        subredditList, verifiedAccountContext.subredditList, convertSubredditListForDropdown]);


    /**
     * Function to filter out entries in the <SemanticDropdown> list based on subreddit name
     * @param options
     * @param query
     * @returns {*}
     */
    function customSearch(options, query) {
        query = query.toLowerCase();
        return options.filter((opt) => {
            return opt.key.toLowerCase().includes(query);
        });
    }

    /**
     * Function called whenever a user selects a subreddit from the <SemanticDropdown> list
     * @param e
     * @param searchQuery
     * @param value
     */
    function handleChange(e, {searchQuery, value}) {
        let selectedNames = value;
        let filtered = selectedNames.filter((next) => subConfigNames.includes(next))
        setSearchQuery("");
        if (filtered && filtered.length > 0) {
            toastContext.addToast(`You already have a config for ${filtered.join(", ")}`, WARNING_TOAST)
            selectedNames = selectedNames.filter((next) => !subConfigNames.includes(next))
            setSelectedSubs(selectedNames);
            return;
        }
        setSelectedSubs(selectedNames);

        if (searchQuery) {
            let subscribedSubNames = subscribedSubredditList.map((s) => s.key)
            // find the subreddit objects that are currently selected (and aren't in the subscribed list)
            let selectedSubsFromSearch = subredditList.filter((sub) => {
                return selectedNames.includes(sub.key) && !subscribedSubNames.includes(sub.key);
            })
            // add the selected subreddits to the subscribed list
            let subscribedListCopy = cloneDeep(subscribedSubredditList)
            subscribedListCopy.push(...selectedSubsFromSearch)
            // set the list of displayed subreddits to the subscribed list (plus selected subreddits)
            setSubredditList(subscribedListCopy);
        }
    }

    /**
     * Called when a user enters text into the <SemanticDropdown> search bar
     * @param a
     * @param searchQuery
     */
    function handleSearchChange(a, {searchQuery}) {
        let filteredOptions = subredditList.filter((sub) => {
            return sub.key.includes(searchQuery);
        });
        if (searchQuery.length > 1 && filteredOptions.length < 5) {
            searchSubreddits(searchQuery);
        }
        setSearchQuery(searchQuery)
        if (!searchQuery.trim() && selectedSubs.length === 0) {
            // if search bar is empty and they haven't selected anything: only show their subscribed subreddits
            setSubredditList(subscribedSubredditList);
        }
    }


    /**
     * Called when a user clicks the create button
     */
    function createButtonClicked() {
        let subreddits = selectedSubs;
        if (searchQuery) {
            if (subreddits.length > 0) {
                subreddits.push(searchQuery)
            } else {
                let foundExistingSub = dropdownRef.current.state.__options
                    .find((opt) => opt.key.toLowerCase() === searchQuery.toLowerCase().trim())
                if (foundExistingSub) {
                    subreddits.push(searchQuery)
                } else {
                    toastContext.addToast("You must select at least one subreddit from the dropdown", ERROR_TOAST);
                    return;
                }
            }
        }
        subreddits = subreddits.map((sub) => sub.replace("/r/", "").replace("r/", "").toLowerCase())
        if (subreddits.length === 1) {
            // create a config for the selected subreddit, then nav to edit page
            createNewConfigs(subreddits).then((resp) => {
                let config = resp[0];
                config.subreddit = subreddits[0];
                nav("/editConfig", {state: {config: config}})
            })
        } else {
            // create a config for each of the selected subreddits
            setIsCreateConfigLoading(true);
            createNewConfigs(subreddits).then((configs) => {
                let callback = (resp) => {

                    let successes = resp["success"].map((sub) => sub.replace("/r/", "").replace("r/", "").toLowerCase())
                    let failures = []
                    for (let i in subreddits) {
                        let sub = subreddits[i]
                        if (!successes.includes(sub)) {
                            failures.push(sub)
                        }
                    }
                    if (successes.length) {
                        toastContext.addToast(`Successfully created configs: ${successes}. Click the 
                            "Edit" button below to customize your roundup and add keywords to search for.`, SUCCESS_TOAST);
                    }
                    if (failures.length) {
                        toastContext.addToast(`Error creating these configs: ${failures}. 
                        Make sure you spelled the subreddit correctly`, ERROR_TOAST);
                    }
                    configContext.loadConfigData(true);
                    setSelectedSubs([]);
                    setIsCreateConfigLoading(false);
                }
                let errCallback = (resp) => {
                    toastContext.addToast("Error, one or more configs were not created.", ERROR_TOAST)
                    configContext.loadConfigData(true);
                    setIsCreateConfigLoading(false);
                }

                let options = {method: "PUT", body: JSON.stringify(configs)};
                httpContext.sendRequest("/api/userconfig/", options)
                    .then(callback)
                    .catch(errCallback)
            });
        }
    }


    function doesPremadeExist(subreddit) {
        return premades.subs.includes(subreddit.toLowerCase());
    }

    async function createNewConfigs(subredditList) {
        let _ = defaultSubredditConfig
        let randomTime = _.mailTime;
        let randomDay = _.mailDay;

        let configList = await Promise.all(subredditList.map((nextSub) => {
            let existingConfig = subConfigs.find((conf) => {
                return conf.subreddit?.toLowerCase() === nextSub.toLowerCase();
            });
            if (existingConfig) {
                toastContext.addToast(`${nextSub} not created, you can only create one config per subreddit`,
                    WARNING_TOAST);
                return Promise.resolve(null);
            } else if (doesPremadeExist(nextSub)) {
                return httpContext.sendRequest(`/api/premade/${nextSub}`, "GET")
                    .then((resp) => {
                        // noinspection UnnecessaryLocalVariableJS
                        let conf = convertBackendToFrontendJson(resp)[0];
                        conf.mailTime = randomTime;
                        conf.mailDay = randomDay;
                        return conf;
                    })
                    .catch((resp) => {
                        toastContext.addToast(`Failed to fetch premade configuration for ${nextSub}. 
                        If you would like to try again, delete this config and re-create it.`, WARNING_TOAST)
                        return getDefaultSubredditConfigObject(nextSub);
                    })
            } else {
                return Promise.resolve(getDefaultSubredditConfigObject(nextSub));
            }
        }))
        configList = configList.filter((config) => config !== null && config !== undefined && config !== {});
        return Promise.resolve(configList);
    }

    /**
     * Sends request to backend to search subreddits with reddit api
     * @param text
     */
    function searchSubreddits(text) {
        let strippedText = text.replace("/r/", "").replace("r/", "").trim();
        if (strippedText.trim()) {
            text = strippedText;
        }
        if (abortController) {
            abortController.abort();
        }
        setAbortController(null)
        if (text.trim() === "") {
            setIsLoading(false)
        } else if (strippedText.trim().length > 1) {
            let newAbortController = new AbortController();
            setAbortController(newAbortController)
            setIsLoading(true);
            let options = {
                method: "GET",
                signal: newAbortController.signal
            }
            httpContext.sendRequest(`/api/search?query=${text}`, options)
                .then(json => {
                    if (json && !(json instanceof DOMException)) {
                        let copy = cloneDeep(subredditList);
                        let existingNameList = copy.map((c) => c.key);

                        let newList = convertSubredditListForDropdown(json, false);
                        newList = newList.filter((nextSub) => {
                            return !existingNameList.includes(nextSub.key)
                        })
                        copy.push(...newList);
                        copy.sort((a, b) => a.subscribers > b.subscribers ? -1 : 1)
                        setSubredditList(copy);
                    }
                    setIsLoading(false)
                })
                .catch((err) => {
                    if (err.toString().includes("AbortError")) {
                        // ignore
                    }
                    setIsLoading(false);
                });
        }
    }

    function goToRedditAuthUrl() {
        // TODO this functin is duplicated in ProfilePage.js
        // create a random state variable
        let state = getCryptoSecureRandom();

        // send the state to the server
        let options = {method: "PUT"}
        httpContext.sendRequest(`/state/${state}`, options).then((resp) => {
            if (resp && resp.success) {
                // go to the reddit auth url
                let url = REDDIT_AUTH_URL;
                url += `&state=${state}`
                window.location.href = url
            } else {
                toastContext.addToast("Error authenticating with reddit at this time. Please try again later.", ERROR_TOAST)
            }
        })
    }

    let body = verifiedAccountContext.isUserLoading ? (
            <Loading/>
        ) : (
            <div>
                {isCreateConfigLoading && (
                    <Loading/>
                )}
                {redditUsername === null ?
                    // Allow user to search for subreddits, or choose to login with reddit
                    <div id={"no_reddit_acct_message"}>
                        <p>Connect your reddit account to import your subscribed subreddits and any existing
                            configs:&nbsp;
                            <RedditButton text={"Connect to reddit "}
                                          clickFunction={goToRedditAuthUrl}/>
                        </p>
                    </div> : (
                        <div>
                            Connected reddit account: {redditUsername}
                        </div>
                    )
                }
                <br/>

                <p><b>Search for subreddits:</b></p>
                <SemanticDropdown
                    ref={dropdownRef}
                    style={{float: "left", width: "75%", marginRight: "5px"}}
                    // https://react.semantic-ui.com/modules/dropdown/#usage-custom-search-function
                    // noResultsMessage={isLoading ? "Searching..." : "No results found"}
                    noResultsMessage={isLoading ? "Searching..." :
                        !searchQuery && selectedSubs.length > 0 ? "Search for more subreddits" : "No results found"}
                    loading={isLoading}
                    multiple
                    fluid
                    options={subredditList}
                    placeholder='Search for a subreddit'
                    search={customSearch}
                    onSearchChange={handleSearchChange}
                    value={selectedSubs}
                    selection
                    searchQuery={searchQuery}
                    onChange={handleChange}
                    delay={{hide: 300, show: 200, search: 500, touch: 50}}
                >
                </SemanticDropdown>
                <Button style={{float: "left"}}
                        id={"create-roundup-button"}
                        onClick={createButtonClicked}>
                    Create
                </Button>
            </div>
        )

    return <CollapsableCard cardId={"create_new_config"} className={"top_level_card"} body={body}
                            header={<h4>Create New Roundup Config</h4>}/>

}